import React from "react";

function ScrollToTop() {
  return (
    <div>
      {/* <a href="#" className="scrollToTop">
        <i className="fas fa-angle-up"></i>
      </a> */}
    </div>
  );
}

export default ScrollToTop;
