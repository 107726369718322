import React from "react";
import Blog from "../components/blogPage/Blog";
import MainLayout from "../components/layout/MainLayout";

function BlogPage() {
  return <>
  <MainLayout>
    <Blog/>
  </MainLayout>
  
  </>;
}

export default BlogPage;
